/*
 * 支付 paymode.js
 */
import {
	getH5WxPayDataApi,
	getPayDataApi,
	getWxPayOpenApi
} from '@/api/pay.js'

export const paymode = (result) => {
  let p = new Promise(function(resolve, reject){
		//做一些异步操作
    if (result.type === 'wx_app') {
      let params = {
        goodsId: result.goodsId,
        type: 'qrcode'
      }
      // console.log('params'.params)
      getWxPayOpenApi(params).then(res => {
        resolve(res)
      })
      
    } else if (result.type === 'zfb') {
      resolve('zfb')
    }
	});
  return p
}