import request from './request'
import website from '@/config/website'
import {
	getStorage
} from '@/utils/storage.js'


//获取vip充值商品列表
export const getVipGoodsListApi = () => {
	return request({
		url: `/api/${website.apiRequestHead}/chat/open/goods/list`,
		method: 'get',
		params: {},
	})
}

//获取H5微信支付参数
export const getH5WxPayDataApi = (id) => {
	return request({
		url: `/api/${website.apiRequestHead}/chat/ai/buy/order?goodsId=${id}`,
		method: 'get',
		params: {},
	})
}

//获取支付参数
export const getPayDataApi = (goodsId,type) => {
	return request({
		url: `/api/${website.apiRequestHead}/chat/ai/buy/new/order`,
		method: 'get',
		params: {
			goodsId,
			type,
		},
	})
}

// 获取H5扫码支付参数
export const getWxPayOpenApi = (params) => {
  return request({
      url: `/api/${website.apiRequestHead}/chat/ai/buy/new/order?goodsId=${params.goodsId}&type=${params.type}`,
      method: 'get',
      data: {},
  })
}

// 获取H5扫码支付状态查询
export const getWxPayStateApi = (params) => {
  return request({
      url: `/api/${website.apiRequestHead}/chat/open/pay/payResultSearch?orderCode=${params}`,
      method: 'get',
      data: {},
  })
}
