import {
  setStorage,
  getStorage
} from "@/utils/storage.js"
import {
} from "@/api/user.js"

const mqtt = {
  state: {
    indexNavValue: getStorage({ name: 'indexNavValue' }) || 'dialogue',//当前首页的导航值
    drawNavValue: getStorage({ name: 'drawNavValue' }) || 'cz', //绘图页面tabbar
    sysConfig: getStorage({ name: 'sysConfig' }) || {}, //系统的配置参数
    specificConfig: getStorage({ name: 'specificConfig' }) || {},
    currMoreData: getStorage({ name: 'currMoreData' }) || {}, //当前功能数据
    indexMenu: getStorage({ name: 'indexMenu' }) || [], //首页服务项
    currIndexItem: {},
    imageIdList: getStorage({ name: 'imageIdList' }) || [],// 图片id
  },
  actions: {

  },
  mutations: {
    SET_INDEX_NAV_VALUE(state, indexNavValue) {
      state.indexNavValue = indexNavValue
      setStorage({
        name: 'indexNavValue',
        content: state.indexNavValue
      })
    },
    SET_DRAW_NAV_VALUE(state, drawNavValue) {
      state.drawNavValue = drawNavValue
      setStorage({
        name: 'drawNavValue',
        content: state.drawNavValue
      })
    },
    SET_SYS_CONFIG(state, sysConfig) {
      state.sysConfig = sysConfig
      setStorage({
        name: 'sysConfig',
        content: state.sysConfig
      })
    },
    SET_SPECIFIC_CONFIG(state, specificConfig) {
      state.specificConfig = specificConfig
      setStorage({
        name: 'specificConfig',
        content: state.specificConfig
      })
    },
    SET_CURR_MORE_DATA(state, currMoreData) {
      state.currMoreData = currMoreData
      setStorage({
        name: 'currMoreData',
        content: state.currMoreData
      })
    },
    SET_INDEX_MENU(state, indexMenu) {
      state.indexMenu = indexMenu
      setStorage({
        name: 'indexMenu',
        content: state.indexMenu
      })
    },
    SET_INDEX_ITEM(state, currIndexItem) {
      state.currIndexItem = currIndexItem
    },
    SET_IMAGEID_LIST(state, imageIdList) {
      state.imageIdList = imageIdList
      setStorage({
        name: 'imageIdList',
        content: state.imageIdList
      })
    },
  }
}

export default mqtt